<template>
    <div>
        <div class="bg-white rounded">
            <div class="d-flex align-items-center p-3 border-bottom">
                <div class="h5 mb-0 bg-green text-white rounded-circle lh-1" style="padding: 0.5em;">01</div>
                <h5 class="m-0 ms-2 text-purple">Relatório TME/TMA</h5>
            </div>
            <div class="bg-light d-flex justify-content-center p-5" v-if="loadingData">
                <b-spinner></b-spinner>
            </div>
            <!-- <div class="p-2">
                <ChartLine :height="125" :data="chartData" :pointRadius="5"/>
            </div> -->
            <div class="p-2" v-else-if="chartData.labels && chartData.labels.length">
                <ChartLine :height="175" :data="chartData" :borderColor="'#000'" :pointRadius="4" :hitRadius="5" :xAxes="lineChart.xAxes" :yAxes="lineChart.yAxes" :legendTime="lineChart.legendTime" />
                <div class="d-flex flex-column align-items-center mt-3 text-secondary">
                    <div class="fs-5">Tempo Médio de espera - <span class="fw-bold" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" :title="`Formato - ${timeFormat}`">{{ avgTimeWaiting }}</span></div>
                    <div class="fs-5">Tempo Médio de atendimento - <span class="fw-bold" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" :title="`Formato - ${timeFormat}`">{{ avgTimeAttendance }}</span></div>
                </div>
            </div>
            <div class="bg-purple text-center text-white p-3" v-else-if="hasSearched">
                Nenhum dado encontrado!
            </div>
            <div class="d-flex align-items-center p-3 w-100">
                <b-row class="m-0 w-100 align-items-end">
                    <b-col class="p-0 pe-3" cols="2">
                        <b-form-group label="Filtro:">
                            <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.average" required>
                                <b-form-select-option value="1" selected>TME</b-form-select-option>
                                <b-form-select-option value="2">TMA</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col class="p-0 pe-3">
                        <b-form-group label="Período:">
                            <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.date">
                                <b-form-select-option value="1">Hoje</b-form-select-option>
                                <b-form-select-option value="2">Esta Semana</b-form-select-option>
                                <b-form-select-option value="3">Este Mês</b-form-select-option>
                                <b-form-select-option value="5">Este Ano</b-form-select-option>
                                <b-form-select-option value="4">Definir Período</b-form-select-option>
                                <b-form-select-option value="6">Definir Data</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <div class="w-100 mt-2" v-if="filters.date == 4">
                            <div class="w-50 pe-1 d-inline-block">
                                <b-form-group label="De:">
                                    <b-form-input type="date" v-model="filters.startDate"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="w-50 ps-1 d-inline-block">
                                <b-form-group label="Até:">
                                    <b-form-input type="date" v-model="filters.endDate"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="w-100 mt-2" v-if="filters.date == 6">
                            <div class="w-100 pe-1 d-inline-block">
                                <b-form-group label="Data:">
                                    <b-form-input type="date" v-model="filters.day"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="p-0 pe-3" cols="3">
                        <!-- <b-form-group>
                            <b-form-radio-group v-model="filters.type">
                                <b-form-radio class="d-inline-flex me-3" value="1">
                                    <div class="ms-2">Departamento</div>
                                </b-form-radio>
                                <b-form-radio class="d-inline-flex w-100" value="2">
                                    <div class="ms-2">Operador</div>
                                </b-form-radio>
                                <b-form-radio class="d-inline-flex" value="3">
                                    <div class="ms-2">Contatos</div>
                                </b-form-radio>
                            </b-form-radio-group>
                        </b-form-group> -->
                        <b-form-group label="Filtrar por:">
                            <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.type">
                                <b-form-select-option value="1">Departamento</b-form-select-option>
                                <b-form-select-option value="2">Operador</b-form-select-option>
                                <b-form-select-option value="3">Contatos</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <div class="mt-2" v-if="filters.type">
                            <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" v-if="filters.type=='1'">
                                <b-form-select-option :value="null">Todos</b-form-select-option>
                                <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{dep.name}}</b-form-select-option>
                            </b-form-select>
                            <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" v-else-if="filters.type=='2'">
                                <b-form-select-option :value="null">Todos</b-form-select-option>
                                <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{op.name}}</b-form-select-option>
                            </b-form-select>
                            <!-- <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" v-else-if="filters.type=='3'">
                                <b-form-select-option :value="null">Todos</b-form-select-option>
                                <b-form-select-option :value="op._id" v-for="op in contacts" :key="op._id">{{op.name}}</b-form-select-option>
                            </b-form-select> -->
                            <div class="position-relative" v-else-if="filters.type=='3'">
                                <div class="border border-dark rounded w-100 text-secondary ps-2 d-flex justify-content-between text-overlay" style="border-color: #ccc !important; padding: 0.38em 0.95em;" role="default" v-b-toggle.collapseContactSelect>
                                    <span class="text-truncate d-flex" v-if="filters?.contacts?.length">
                                        <span v-for="(contact,i) in filters.contacts" :key="contact.id">
                                            {{ contactName(contact) }}<span v-if="i+1 < filters.contacts.length">,&nbsp;</span> 
                                        </span>
                                    </span>
                                    <span v-else>
                                        &nbsp;
                                    </span>
                                    <span class="d-flex align-items-center">
                                        <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em; filter: brightness(0) drop-shadow(0px 1px 0 black) drop-shadow(0px -1px 0 black);" font-scale="0.75"></b-icon>
                                    </span>
                                </div>
                                <b-collapse id="collapseContactSelect" ref="collapseContactSelect" class="mt-2 position-absolute bg-white border w-100 rounded overflow-y-auto" style="z-index: 1; max-height: 10em;">
                                    <b-form-checkbox-group
                                        v-model="filters.contacts"
                                        style="z-index: 1"
                                    >
                                        <div v-for="item in contacts" :key="item.id">
                                            <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="item._id"> 
                                                <div class="ms-1" role="button">{{ item.name }}</div>
                                            </b-form-checkbox>
                                        </div>
                                    </b-form-checkbox-group>
                                    <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseContactSelect>
                                    </div>
                                </b-collapse>
                            </div>
                            <!-- <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" disabled v-else></b-form-select> -->
                        </div>
                    </b-col>
                    <b-col class="p-0">
                        <b-button class="w-100" style="margin-top: 1.85em" variant="primary" @click="getData()">Gerar</b-button>
                    </b-col>
                </b-row>
                <div></div>
            </div>
        </div>
        <div class="bg-white rounded mt-3" v-if="hasSearched">
            <div class="d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center">
                    <div class="h5 mb-0 bg-green text-white rounded-circle" style="padding: 0.5em;">02</div>
                    <h5 class="m-0 ms-2 text-purple">Relatório Listagem de Atendimento</h5>
                </div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Atendimentos</div>
                    <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2">{{ tableItems.count || 0 }}</div>
                </div>
            </div>
            <div class="border-top">
                <div class="bg-white d-flex justify-content-center p-5" v-if="loadingData">
                    <b-spinner></b-spinner>
                </div>
                <div v-else-if="tableItems?.length">
                    <b-table class="reportContacts m-0" tbody-tr-class="align-middle" hover head-variant="dark" :items="tableItems" :fields="tableFields">
                        <template #cell(attendanceEndTime)="data">
                            {{ data.item.attendanceEndTime | date('MMM DD [●] HH:mm:ss') }}
                        </template>
                        <template #cell(attendanceStartTime)="data">
                            {{ data.item.attendanceStartTime | date('MMM DD [●] HH:mm:ss') }}
                        </template>
                        <template #cell(timeWaiting)="data">
                            {{ (data.item.timeWaiting) | date('DD:HH:mm:ss', true) }}
                        </template>
                        <template #cell(timeAttendance)="data">
                            {{ (data.item.timeAttendance) | date('DD:HH:mm:ss', true) }}
                        </template>
                        <template #head(messages)>{{""}}</template>
                        <template #cell(messages)="data">
                            <div class="d-flex justify-content-end">
                                <b-button class="border-0 px-4 me-2" pill @click="selectClient(data.item); getForwardList()" v-b-modal.modalForward v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Encaminhar Atendimento" v-if="data.item.status == 'finished' || data.item.status == 'abandoned'">
                                    <b-icon icon="box-arrow-in-right"></b-icon>
                                </b-button>
                                <b-button class="bg-green border-0 px-4" pill @click="selectClient(data.item); loadMessages(data.item._id,data.item.operatorId)" v-b-modal.contactMessages v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Visualizar Atendimento">
                                    <b-icon icon="chat-square-text"></b-icon>
                                </b-button>
                            </div>
                        </template>
                        <template #cell(clientName)="data">
                            <div class="d-flex align-items-center">
                                <div class="me-2">
                                    <b-avatar class="bg-secondary text-white" size="3rem" :src="data.item.photoURL"></b-avatar>
                                </div>
                                <div>
                                    {{ data.item.clientName }} 
                                </div>
                            </div>
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-between align-items-center px-4 py-3" v-if="tableItems && tableItems.count > 10">
                        <b-pagination class="m-0"
                            :total-rows="tableItems.count"
                            v-model="currentPage"
                            :per-page="perPage"
                            @change="pageChange"
                        ></b-pagination>
                        <span class="pagdescription">
                            <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{ (currentPage * perPage > tableItems.count) ? tableItems.count : currentPage * perPage }} de {{ tableItems.count }} atendimentos</div>
                        </span>
                    </div>
                </div>
                <div class="text-center p-3 text-secondary" v-else>
                    Nenhum dado encontrado!
                </div>
            </div>
        </div>
        <b-modal id="contactMessages" ref="contactMessages" title="Visualizar Atendimento" header-class="py-0" hide-footer>
            <div class="d-flex align-items-center mb-2" v-if="clientSelected">
                <b-avatar class="bg-secondary text-white" size="3rem" :src="clientSelected.photoURL"></b-avatar>
                <div class="text-purple fw-bold ms-2">
                    {{ clientSelected.clientName }} 
                </div>
            </div>
            <div>
                <Whatsapp :messages="contactMessages" :height="'72vh'" :mediaURL="mediaURL" />
            </div>
        </b-modal>
        <b-modal id="modalForward" ref="modalForward" hide-footer title="Encaminhar Atendimento" header-class="py-0" v-if="clientSelected">
            <b-form-group label="Encaminhar Para:" label-class="text-purple fw-semibold">
                <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="forward.operator">
                    <b-form-select-option :value="op" v-for="op in onlineOperators" :key="op._id">{{ op.name }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3">
                <b-button class="text-white" variant="green" @click="startAttendance()">Encaminhar</b-button>
                <b-button class="ms-2" variant="danger" @click="$bvModal.hide('modalForward')">Cancelar</b-button>
            </div>
        </b-modal>
        <FAB ref="fabs" :screenshotBtn="true" :xlsBtn="true" @exportXlsData="exportXlsData" />
    </div>
</template>

<script>
import ChartLine from './chartLine.vue'
import Whatsapp from './whatsapp.vue'
import api from '../services/apiService.js'
import utils from '../utils/utils.js'
import FAB from './FAB.vue'

export default {
    props: [
        'user', 'timestampDiff', 'mediaURL'
    ],
    components: {
        ChartLine, Whatsapp, FAB
    },
    data() {
        return {
            filters: {
                date: 3,
                average: 1
            },
            clientSelected: null,
            objAux: {},
            forward: {},
            operators: [],
            onlineOperators: [],
            departments: [],
            contacts: [],
            chartData: {},
            tableFields: [
                {
                    key: 'clientName',
                    label: 'Contato',
                },
                {
                    key: 'clientNumber',
                    label: 'Telefone'
                },
                {
                    key: 'timeWaiting',
                    label: 'Tempo em Espera'
                },
                {
                    key: 'timeAttendance',
                    label: 'Tempo em Atendimento'
                },
                // {
                //     key: 'clientName',
                //     label: 'Notificações',
                // },
                {
                    key: 'attendanceStartTime',
                    label: 'Data de início do atendimento',
                    sortKey: true,
                },
                {
                    key: 'attendanceEndTime',
                    label: 'Data de fim do atendimento',
                    sortKey: true,
                },
                {
                    key: 'messages',
                    class: ''
                }
            ],
            tableItems: [],
            currentPage: 1,
            perPage: 10,
            contactMessages: [],
            loadingData: false,
            hasSearched: false,
            avgTimeAttendance: "DD:HH:MM:SS",
            avgTimeWaiting: "DD:HH:MM:SS",
            lineChart: {
                yAxes: {},
                xAxes: {
                    display: true,
                    ticks: {
                        display: true
                    }
                },
                legendTime: true
            },
            timeFormat: "HH:MM:SS"
        }
    },
    mounted: function() {
        // this.getData()
        this.onCloseModal()
        this.getDepartments()
        this.getOperators()
        this.getAllContacts()
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.forward = {}
                this.clientSelected = null
                this.objAux = {}
                this.contactMessages = []
            })
        },
        async getData(page=1) {
            if(!this.filters.average) {
                return this.$emit('msg', {
                    text: 'Campo "Filtro" não pode ser vazio!',
                    success: false
                })
            }

            this.loadingData = true
            // if(!this.filters.typeId)
            //     this.filters.type = null
            let resp = await this.getReportAverage({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, day: this.filters.day, page })
            console.log('resp report',resp)
            if(resp) {
                this.tableItems = resp
            } else {
                this.tableItems = []
            }
            let resp2 = await this.getReportAverageCount({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, day: this.filters.day })
            console.log('resp count',resp2)
            if(resp2) {
                this.tableItems.count = resp2.totalCount
                let labels = []
                let values = []

                if(this.filters.average == '1')
                    values.push({
                        label: "Tempo Médio em Espera",
                        backgroundColor: "rgba(41, 128, 185, 0.75)",
                        data: []
                    })

                if (this.filters.average == '2')
                    values.push({
                        label: "Tempo Médio em Atendimento",
                        backgroundColor: "rgba(39, 174, 96, 0.75)",
                        data: []
                    })

                await this.$set(this.chartData, 'labels', labels)
                await this.$set(this.chartData, 'values', values)

                this.avgTimeWaiting = this.formatTime(resp2.avgTimeWaiting)
                if(this.avgTimeWaiting.length > 8)
                    this.timeFormat = "DD:HH:MM:SS"
                else
                    this.timeFormat = "HH:MM:SS"
                this.avgTimeAttendance = this.formatTime(resp2.avgTimeAttendance)
                if(!this.avgTimeWaiting.length > 8 && this.avgTimeAttendance.length > 8)
                    this.timeFormat = "DD:HH:MM:SS"
                else if(!this.avgTimeWaiting.length > 8)
                    this.timeFormat = "HH:MM:SS"

                await resp2.count.map(async el => {
                    if(el) {
                        this.filters.average == '1' && values.at(0).data.push(el.avgTimeWaiting || 0) // TME
                        this.filters.average == '2' && values.at(-1).data.push(el.avgTimeAttendance || 0) // TMA
                    } else
                        await values.map(value => value.data.push(0))
                    
                    if(el.hour)
                        labels.push(el.hour)
                    else if(el.month)
                        labels.push(el.month)
                    else
                        labels.push(this.$options.filters.date(el.date,'DD/MM/YY',true))
                    // values.push(el.count)
                    return el
                })

                // values = values.filter(el => {
                //     return !el.data.every(el => el == 0 || el == undefined)
                // })

                await this.$set(this.chartData, 'labels', labels)
                await this.$set(this.chartData, 'values', values)
            } else {
                await this.$set(this.chartData, 'labels', [])
                await this.$set(this.chartData, 'values', [])
            }
            this.loadingData = false
            this.hasSearched = true
            return
        },
        findStatus(status,array) {
            let find = array.find(e => e.status == status)
            if(find)
                return find.count
            else 
                return 0
        },
        async getReportAverage(data) {
            let resp = await api.getReportAverage(data)
            if(resp.statusCode == 200) {
                return resp.attendances
            }
            return false
        },
        async getReportAverageCount(data) {
            let resp = await api.getReportAverageCount(data)
            if(resp.statusCode == 200) {
                return resp.attendances
            }
            return false
        },
        getForwardList() {
            this.getOnlineOperators()
            this.getDepartments()
        },
        async getOperators() {
            let resp = await api.getOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.operators = []
                this.operators['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators
        },
        async getOnlineOperators() {
            let resp = await api.getOnlineOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.onlineOperators = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.onlineOperators = resp.operators
        },
        async getDepartments() {
            let resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        async getAllContacts() {
            let resp = await api.getAllContacts(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.contacts = []
                return
            }
            this.contacts = resp.contacts.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        },
        async loadMessages(attendanceId, operatorId) {
            const resp = await api.loadMessages({
                attendance: {
                    _id: attendanceId
                },
                operatorId
            })

            if (resp.statusCode === 200) {
                resp.messages.messages.map(el => {
                    if(this.timestampDiff > 100)
                        el.timestamp -= this.timestampDiff
    
                    if(el.timestamp > 9999999999)
                        el.timestamp /= 1000
                })
    
                this.contactMessages = resp.messages.messages
            }
        },
        pageChange(page) {
            if(page != this.currentPage) {
                this.currentPage = page
                this.getData(page)
            }
        },
        async startAttendance() {
            if(this.forward.operator) {
                let contact = this.clientSelected
                // in this cases, contact comes from old attendance
                if(!contact.number) contact.number = contact.clientNumber
                if(!contact.name) contact.name = contact.clientName
    
                let resp = await api.retrieveAttendance(this.forward.operator.channelId, { contact, operatorId: this.forward.operator._id })
                // console.log('resp retrieveAtt',resp)
                let msg = null
                if(resp.statusCode == 200) {
                    msg = {
                        text: "Atendimento encaminhado com sucesso!",
                        success: true
                    }
                    if(this.$refs['modalForward'])
                        this.$refs['modalForward'].hide()
                } else if(resp.statusCode == 406) {
                    let text

                    switch(resp.attendance.status) {
                        case 'opened':
                            text = "Este contato está com atendimento em andamento no ChatBot"
                            break
                        case 'offline_operators':
                            text = "Este contato está com atendimento em andamento na fila de espera"
                            break
                        default:
                            if (resp.attendance.operatorName)
                                text = `Este contato está com atendimento em andamento com ${resp.attendance.operatorName}!`
                            else
                                text = "Este contato está com atendimento em andamento"
                    }

                    msg = {
                        text,
                        success: false
                    }
                } else if(resp.statusCode == 408) {
                    msg = {
                        text: "O horário de atendimento deste operador foi finalizado!",
                        success: false,
                        countdown: 10
                    }
                } else if(resp.statusCode === 409) {
                    msg = {
                        text: "Este contato está em pesquisa de satisfação!",
                        success: false
                    }
                } else {
                    msg = {
                        text: "Ocorreu um erro!",
                        success: false
                    }
                }
                if(msg) {
                    this.$emit('msg',msg)
                }
            }
        },
        selectClient(item) {
            this.clientSelected = item
            this.objAux = Object.assign({}, item);
            console.log({item})
        },
        formatTime(ms) { // DD:HH:MM:SS
            if(ms) {
                let pad = function(n){ return n < 10 ? '0' + n : n; };
                const days = Math.floor(ms / (24*60*60*1000));
                const daysms = ms % (24*60*60*1000);
                const hours = Math.floor(daysms / (60*60*1000));
                const hoursms = ms % (60*60*1000);
                const minutes = Math.floor(hoursms / (60*1000));
                const minutesms = ms % (60*1000);
                const sec = Math.floor(minutesms / 1000);

                let time

                if(!days && this.timeFormat !== "DD:HH:MM:SS")
                    time = pad(hours) + ":" + pad(minutes) + ":" + pad(sec)
                else
                    time = pad(days) + ":" + pad(hours) + ":" + pad(minutes) + ":" + pad(sec)

                return time
            }
            return "00:00:00"
        },
        formatTime2(t) { // DD:HH:MM
            if(t) {
                let cd = 24 * 60 * 60 * 1000,
                    ch = 60 * 60 * 1000,
                    d = Math.floor(t / cd),
                    h = Math.floor( (t - d * cd) / ch),
                    m = Math.round( (t - d * cd - h * ch) / 60000),
                    pad = function(n){ return n < 10 ? '0' + n : n; };
                if( m === 60 ){
                    h++;
                    m = 0;
                }
                if( h === 24 ){
                    d++;
                    h = 0;
                }
                return [pad(d), pad(h), pad(m)].join(':')
            }
            return null
        },
        contactName(_id) { // returns the name of the given _id contact
            if(this.contacts.length){
                let contact = this.contacts.find(el=>el._id == _id)
                if(contact)
                    return contact.name
            }
        },
        async exportXlsData() {
            try {
                const resp = await api.getReportAverageFull({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, day: this.filters.day })
                if(resp.statusCode === 200) {
                    const model = resp.attendances.map(d=>{
                        const model = {
                            Contato: d.clientName,
                            Whatsapp: d.clientNumber,
                            Tempo_espera: this.$options.filters.date(d.timeWaiting,'DD:HH:mm:ss', true),
                            Tempo_atendimento: this.$options.filters.date(d.timeAttendance,'DD:HH:mm:ss', true),
                            Inicio_atendimento: this.$options.filters.date(d.attendanceStartTime,'DD/MM/YY HH:mm:ss'),
                            Fim_atendimento: this.$options.filters.date(d.attendanceEndTime,'DD/MM/YY HH:mm:ss'),
                            Operador: d.operatorName
                        }
                        console.log('model', model)
                        return model
                    })
                    const xls = utils.convertToXLS(model)
                    utils.downloadXLS(xls, `Relatorio-TME/TMA-${new Date().getTime()}`)
                } else {
                    console.log(resp)
                }
            } catch(error) {
                console.error(error)
            }
            this.$refs.fabs.$emit('exportedXls', true)
        }
    },
}
</script>

<style>
    .reportContacts td, .reportContacts th{
        padding: 1em 1.5em !important;
    }
</style>

<style scoped>
    
</style>